@import "~antd/dist/antd.less";

//Front-page

.App {
  text-align: center;
  background-color: #cacbca;
}

.wedding-logo {
  height: 75vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #798878;
  text-decoration: none;
}

.appButton {
  background-color: rgb(234, 224, 220);
}

//Landing page (wedding info)

.weddingDate {
  text-align: center;
  font-family: "Petit Formal Script";
  margin-top: 16px;
}

.listHeader {
  text-align: center;
}

.leafContainer {
  height: 15vh;
}

.imgBorder {
  max-width: 50%;
  align-items: top;
  justify-content: center;
  margin-left: 25vw;
}

.divider {
  margin-top: -10px;
}

.borderContainer {
  height: 3vh;
}

.leafBorder {
  max-width: 75%;
  justify-content: center;
  margin-top: -20px;
  margin-left: 80px;
  margin-bottom: 0px;
  display: flex;
}

.countdownContainer {
  align-items: top;
  justify-content: left;
  margin-left: -45px;
  margin-top: -10px;
}

//Carousel

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgDiv {
  height: 85vh;
  width: 35vh;
  display: flex;
  vertical-align: middle;
  justify-content: center;
}

.carouselImg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.bottom-center {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
}

//Header

.mainHeader {
  height: 25vh;
  justify-content: space-between;
}

.headerLogo {
  position: relative;
  height: 25vh;
}

.navMenu {
  margin-top: 8vh;
  justify-content: flex-end;
  border-bottom: 1px solid #cacbca;
}

//Footer

.footerLogo {
  position: relative;
  height: 10vh;
}

.mainFooter {
  margin-top: 16px;
}

.footerText {
  text-align: left;
  font-family: "Petit Formal Script";
  margin-top: 3vh;
  margin-bottom: auto;
}

//RSVP Page

.rsvpCard {
  background-image: url("./pictures/rsvpBackground.webp");
  border-radius: 25px;
  filter: drop-shadow(10px 5px 4px #696969);
}

.rsvpBorder {
  max-width: 80%;
  align-items: top;
  justify-content: center;
  // margin-left: 25vw;
}

.rsvpLogoContainer {
  height: 40vh;
  align-items: center;
  justify-content: center;
}

.rsvp-logo {
  height: 35vmin;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.rsvpFont {
  font-family: "Petit Formal Script";
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: rgb(234, 224, 220);
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: rgb(234, 224, 220);
}

@primary-color: #798878;@layout-header-background: #cacbca;@layout-header-color: @text-color;@layout-footer-background: @layout-header-background;@menu-bg: #cacbca;@divider-color: rgb(234,224,220);@font-family: Arimo;